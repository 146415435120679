<template>
    <nav class="navbar">
        <!-- Logo -->
        <div class="logo">
            <img src="../assets/logo1.png" alt="Logo" />
        </div>

        <!-- Main Menu -->
        <ul :class="{ active: menuActive }" class="nav-menu">
            <li><a href="/">🌐 Community</a></li>
            <li><a href="/about">ℹ️ About Us</a></li>
            <li><a href="/products">🛍️ Products</a></li>

            <!-- Resources Dropdown -->
            <li class="dropdown" @click="toggleDropdown">
            <a href="#">📚 Resources</a>
            <ul v-if="dropdownActive" class="dropdown-menu">
            <!-- <li><router-link to="/documentation">📄 Documentation</router-link></li> -->
            <!-- <li><router-link to="/tutorials">🎓 Tutorials & How-To Guides</router-link></li> -->
            <li><router-link to="/faqs">❓ FAQs</router-link></li>
            <!-- <li><router-link to="/integrations">🔗 Integration Guides</router-link></li> -->
            <!-- <li><router-link to="/case-studies">📊 Case Studies & Reports</router-link></li> -->
            <!-- <li><router-link to="/community">📢 Community & Support</router-link></li> -->
            </ul>
            </li>

            <!-- <li><a href="/impact">🌍 Impact</a></li> -->
            <li><a href="/contactus">💬 Support</a></li>
        </ul>

        <!-- Mobile Menu Icon -->
        <div class="menu-icon" @click="toggleMenu">
            &#9776;
        </div>
    </nav>
</template>

<script setup>
    import { ref } from 'vue';

    const menuActive = ref(false);
    const dropdownActive = ref(false);

    const toggleMenu = () => {
        menuActive.value = !menuActive.value;
    };

    const toggleDropdown = () => {
        dropdownActive.value = !dropdownActive.value;
    };
</script>

<style scoped>

    /* Basic Navbar Layout */
    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        background-color: #f8f9fa;
        border-bottom: 1px solid #ddd;
    }

    /* Logo */
    .logo img {
        width: 150px;
        height: auto;
    }

    /* Main Menu */
    .nav-menu {
        display: flex;
        list-style-type: none;
        gap: 20px;
    }

    .nav-menu a {
        text-decoration: none;
        color: #333;
        padding: 8px 12px;
        transition: 0.3s;
        font-size: 20px;
    }

    .nav-menu a:hover {
        color: #007bff;
    }

    /* Dropdown */
    .dropdown {
        position: relative;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: white;
        border: 1px solid #ddd;
        list-style-type: none;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        width: 200px;
    }

    .dropdown-menu li {
        padding: 8px 12px;
        cursor: pointer;
    }

    .dropdown-menu li:hover {
        background-color: #f0f0f0;
    }

    /* Mobile Menu */
    .menu-icon {
        display: none;
        cursor: pointer;
        font-size: 24px;
    }

    /* Responsive Design */
    @media (max-width: 768px) {
        .nav-menu {
            display: none;
            flex-direction: column;
            position: absolute;
            top: 60px;
            right: 10px;
            background: white;
            border: 1px solid #ddd;
            padding: 10px;
            width: 200px;
        }

        .nav-menu.active {
            display: flex;
        }

        .menu-icon {
            display: block;
        }
    }
</style>
