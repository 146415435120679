import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Documentation from '../views/Documentation.vue';
import Tutorials from '../views/Tutorials.vue';
import FAQs from '../views/FAQs.vue';
import IntegrationGuides from '../views/IntegrationGuides.vue';
import CaseStudies from '../views/CaseStudies.vue';
import Community from '../views/Community.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/contactus',
    name: 'contactus;',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ContactUs.vue'),
  },
  {
    path: '/resources',
    name: 'resources',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Resources.vue'),
  },
  {
    path: '/impact',
    name: 'impact',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ImpactPage.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ProductsPage.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import('../views/TermsOfService.vue'),
  },

  {
    path: '/documentation',
    name: 'documentation',
    component: Documentation,
  },
  {
    path: '/tutorials',
    name: 'tutorials',
    component: Tutorials,
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: FAQs,
  },
  {
    path: '/integrations',
    name: 'integrations',
    component: IntegrationGuides,
  },
  {
    path: '/case-studies',
    name: 'case-studies',
    component: CaseStudies,
  },
  {
    path: '/community',
    name: 'community',
    component: Community,
  },


];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
