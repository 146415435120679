<template>
    <div>
        <h1>📢 Community & Support</h1>
        <p>Forums, training materials, and support contacts.</p>
    </div>
</template>

<script>
export default {
    name: 'CommunitySupport'
}
</script>
