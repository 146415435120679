<template>
    <div>
        <h1>📄 API Documentation Guide</h1>
        <p>Find API guides, system setup instructions, and configuration details here.</p>
        <ul>
            <li><a href="https://github.com/openchlai/General_Documenation">General Documentation</a></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'DocumentationPage'
    }
</script>
