<template>
    <div>
        <h1>🎓 Tutorials & How-To Guides</h1>
        <p>Step-by-step guides on using key features.</p>
    </div>
</template>

<script>
export default {
    name: 'TutorialsAndHowToGuides'
}
</script>
